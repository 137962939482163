/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

$blue:    #013765;
$pink:    #F0637B;

$btn-border-radius: 0;
$btn-border-radius-sm:  0;
$btn-border-radius-lg:  0;
$btn-focus-width: 0;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: none;


$toast-border-radius: 0;

$form-floating-padding-y: 0.75rem;
$form-floating-padding-x: 0.5rem;
$form-select-padding-x: 0.5rem;

$form-floating-height:            calc(3rem + 2px) !default;
$form-floating-input-padding-t: 1.15rem;
$form-floating-label-transform:   scale(.85) translateY(-.6rem) translateX(.15rem) !default;

$font-weight-bold: 500;
// $form-floating-line-height: 1;

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";

@import "mixins";
@import "utilities";

@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: rgba($color,0.1);
  }
}

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack
