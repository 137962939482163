.design-colors .color-container {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #aaa;
  vertical-align: middle;
}

.design-colors .color-container .color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--c, transparent);
  vertical-align: middle;
  margin: 4px;
  background: var(--b, transparent);
}

.ribbon {

  border-top:0!important;
  position: absolute;
  top:0;
  right:0;
      z-index: 2;
}

.animateFlexbox {
  transition: width 0.2s;
}

.designElement {
  width: 250px;
  max-width: 40%;
  flex-shrink:0;
}

@media only screen and (min-width: 992px) {
  .h-lg-100 {
    height:100%;
  }

}


.form-check-input:disabled {
  background: #dfdfdf;
}



.alert-sm {
  border:0;
  min-height: 30px;
  line-height:30px;
  padding:0 46px 0 15px;
}

.alert-sm.alert-dismissible .btn-close {
  min-height: 30px;
  padding: 0px 15px;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    background: #21252920;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background: #0d6efd20;
}


html, body, #root {
    height:100%;
  }

  .dropshadow {
    box-shadow: 0px 0px 10px 5px #9999994f;
  }

  .dark .dropshadow {
    box-shadow: 0px 0px 10px 5px  rgba(#000, .2);
  }

  .dark .menu {
    background: #0d2035;
  }

.dark .bg-white {
  background: #0d2035!important;
}


svg .raster .raster {
  //fill: rgb(0, 0, 0);
  fill-rule: evenodd;
  fill-opacity: 0.5;
}

svg .noStroke {
  stroke: none;
}

svg .engrave {
  stroke-opacity: 0.5;
}

// svg .material {
//   -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
//   filter: drop-shadow( 0px 0px 6px rgba(0, 0, 0, .4));
//   fill: white;
//   stroke:#ddd;
// }

.Toastify__toast-container:not(.Toastify__toast-container--bottom-center) {
  pointer-events: none;
}

.Toastify__toast-container.Toastify__toast-container--bottom-center {
  width: auto;
  max-width: 90vw;
}

.clickThrough *{
  pointer-events: none;
  transition: opacity 0.25s;
}

.hover.clickThrough{
  pointer-events: none;
  opacity:0.2;
}

.clickThrough {
  transition: opacity 0.2s, height 0.1s, margin-top 0.1s, margin-bottom 0.1s;
}

.clickThrough:hover {
  cursor:pointer;
  opacity:0.5
}

.notification.fadeOut {
  opacity:0;
}
.notification.slideOut {
  height:0px;
  margin-top:0 !important;
  margin-bottom:0 !important;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout{
  padding-top: 4em;
}


@media (min-width: 992px){
  .checkoutBg::after {
    content: "";
    display: block;
    width: 50%;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    background: #fcfcfc;
    z-index: -1;
  }


  .checkoutBg::after {
    left: 50%;
    background-position: left top;
  }

  .quoteBg::after {
    content: "";
    display: block;
    width: 50%;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    background: #fcfcfc;
    z-index: -1;
  }
  
  .quoteBg {
    .col-lg-8 {
      background: #fcfcfc;
    }
  }


  .quoteBg::after {
    left: 50%;
    background-position: left top;
  }
}

.react-slidedown.transitioning {
  overflow-x:hidden;
}

.bgcontain {
  background-position:center;
  background-size:contain;
  background-repeat:no-repeat;
}

.btn-circle {
    width: 31px;
    height: 31px;
    padding: 6px 0px;
    border-radius: 15.5px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.zIndex-1070{
  z-index:1070;
  position:relative;
}

.svg-inline--fa{
  outline:none;
}

.fs-7 {
  font-size: 0.875rem !important;
}
.fs-8 {
  font-size: 0.75rem !important;
}
.fs-9 {
  font-size: 0.625rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

.transition {
  transition: transform 0.25s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}


.expand-right{
  max-width: 33.75px;
  transition: max-width 0.25s ease;
  overflow:hidden;
  display: inline-flex;
  align-items: center;
  i {
    width: 1em;
  }
  &:hover {
    max-width: 250px;
  }
  .text {
    white-space: nowrap;
    padding-left: 8px;
  }
}

.tooltipContainer {
  z-index: 1080;
  position: relative;
}