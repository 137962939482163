@import "functions";
@import "variables";
@import "mixins";


.dark {

    /* redefine theme colors for dark theme */
    $primary: #fff;
    $secondary: #111111;
    // $success: #222222;
    $dark: #000;

    $card-bg: #0d2035;

    $theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "danger": $danger,
        "info": $indigo,
        "dark": $dark,
        "light": $light,
    );

    $color-1: #102741;
    $text-color: #5390d5;
    $table-color: $text-color;

    $border-color:                 rgba(#193e66, 1);
    $card-border-color:                 $border-color;
    $input-border-color:                 $border-color;
    $input-focus-border-color:    $border-color;

    $nav-tabs-link-active-bg: $color-1;
    $nav-tabs-link-active-border-color: $border-color;
    $input-bg: $color-1;

    $input-focus-bg: #102741;

    /* redefine theme color variables */
    @each $color, $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    /* redefine theme color rgb vars (used for bg- colors) */
    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
    @each $color, $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    $body-color: #eeeeee;
    $body-bg: #060f19;

    --#{$variable-prefix}body-color: #{$body-color};
    --#{$variable-prefix}body-bg: #{$body-bg};

    @import "bootstrap";
}
